/*
	========================================
	JESSICA ONE PAGE RESUME THEME
	========================================
	
	@author			: @_TemplateNinja
	@version		: v1.2
	@date-create	: 09/04/2014
	@last-update	: 03/21/2015
	@file-name		: skin.less

*/
/*
	========================================
	TABLE OF CONTENT
	========================================
	
	[1. IMPORT SOURCE]
	[2. GLOBAL STYLE]
	[3. BUTTON]
	[4. TYPHOGRAPHY]
	[5. PAGINATION]
	[6. TAGS]
	[7. TABLE]
	[8. NAVBAR]
	[9. SERVICE]
	[10. PORTFOLIO]
	[11. CONTACT]
	[12. SOCIAL]
	[13. FOOTER]

*/
/*
	==================
	[1. IMPORT SOURCE]
	==================
*/
/*
	========================================
	JESSICA ONE PAGE RESUME THEME
	========================================
	
	@author			: @_TemplateNinja
	@version		: v1.2
	@date-create	: 09/04/2014
	@last-update	: 03/21/2015
	@file-name		: variables.less

*/
/*
	========================================
	TABLE OF CONTENT
	========================================
	
	[1. URL]
	[2. FONT]
	[3. COLOR]
	[4. MIXIN]

*/
/*
	============================
	[1. URL]
	*
	* ex : @{imgurl}/your-image-name.jpg
	*
	============================
*/
/*
	============================
	[2. FONT]
	============================
*/
/*
	============================
	[3. COLOR]
	============================
*/
/*
	============================
	[4. MIXIN]
	============================
*/
/* 
	==================
	[2. GLOBAL STYLE]
	==================
*/
::-moz-selection {
  background-color: #ed2025;
}
::-webkit-selection {
  background-color: #ed2025;
}
::selection {
  background-color: #ed2025;
}
a {
  color: #ed2025;
}
/*
	==================
	[3. BUTTON]
	==================
*/
.btn.btn-lucky {
  background-color: #ed2025;
  border-color: #ed2025;
}
.btn.btn-lucky-primary:hover,
.btn.btn-lucky-primary:focus,
.btn.btn-lucky-primary:active,
.btn.btn-lucky-primary.active {
  background-color: #ed2025;
  border-color: #ed2025;
}
/*
	==================
	[4. TYPHOGRAPHY]
	==================
*/
.page-title h2::after {
  content: '';
  background-color: #ed2025;
}
/*
	==================
	[5. PAGINATION]
	==================
*/
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #ed2025;
  border-color: #ed2025;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #ed2025;
  border-color: #ed2025;
}
/*
	==================
	[6. TAGS]
	==================
*/
.tags > li > a:hover,
.tags > li > a:focus {
  background-color: #ed2025;
}
.tags > li > a:hover::after,
.tags > li > a:focus::after {
  content: '';
  border-left-color: #ed2025;
}
/*
	==================
	[7. TABLE]
	==================
*/
table.table-alt th {
  border: 1px solid #ed2025 !important;
  border-right: 1px solid #ddd !important;
  background: #ed2025;
}
table.table-alt.table-bordered thead:first-child tr:first-child > th:last-child {
  border-right: 1px solid #ed2025 !important;
}
table.table-alt.table-bordered tbody:first-child tr:first-child > td:last-child {
  border-right: 1px solid #ed2025 !important;
}
table.table-alt.table-bordered tbody:first-child tr:first-child > th:last-child {
  border-right: 1px solid #ed2025 !important;
}
/*
	==================
	[8. NAVBAR]
	==================
*/
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #ed2025;
}
@media screen and (max-width: 767px) {
  .navbar-default .navbar-brand {
    background-color: #ed2025;
    color: #fff;
  }
}
.navbar-default.navbar-scroll .navbar-brand {
  color: #ed2025;
}
@media screen and (max-width: 767px) {
  .navbar-default.navbar-scroll .navbar-brand {
    color: #fff;
  }
}
.navbar-default.navbar-scroll .navbar-nav > li > a:hover,
.navbar-default.navbar-scroll .navbar-nav > li > a:focus {
  color: #ed2025;
}
.navbar-default.navbar-scroll .navbar-nav > .active > a,
.navbar-default.navbar-scroll .navbar-nav > .active > a:hover {
  color: #ed2025;
}
.navbar-default.navbar-scroll .navbar-nav li.dropdown.open > a,
.navbar-default.navbar-scroll .navbar-nav li.dropdown.open > a:hover {
  background-color: #ed2025;
}
@media screen and (max-width: 767px) {
  .navbar-default.navbar-scroll .navbar-nav .navbar-nav > li > a:hover,
  .navbar-default.navbar-scroll .navbar-nav .navbar-nav > li > a:focus {
    color: #ed2025;
  }
  .navbar-default.navbar-scroll .navbar-nav .navbar-nav > .active > a,
  .navbar-default.navbar-scroll .navbar-nav .navbar-nav > .active > a:hover {
    color: #ed2025;
  }
}
.navbar-default .navbar-nav > li > a:hover::after,
.navbar-default .navbar-nav > li > a:focus::after {
  content: '';
  background-color: #ed2025;
}
.navbar-default .navbar-nav > .active > a::after,
.navbar-default .navbar-nav > .active > a:hover::after {
  content: '';
  background-color: #ed2025;
}
/* dropdown menu */
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #ed2025;
}
.dropdown-menu > .active > a::after,
.dropdown-menu > .active > a:hover::after,
.dropdown-menu > .active > a:focus::after {
  content: '';
  background-color: #ed2025;
}
/*
	==================
	[9. SERVICE]
	==================
*/
.service-content:hover > .service-icon .glyphicon,
.service-content-alt:hover > .service-icon .glyphicon,
.service-content:hover > .service-icon .fa,
.service-content-alt:hover > .service-icon .fa {
  color: #ed2025;
}
/*
	==================
	[10. PORTFOLIO]
	==================
*/
.portfolio-item > .item-link > .item-desc h3::after {
  content: '';
  background-color: #ed2025;
}
.portfolio-item:hover > .item-link > .item-desc h3 {
  color: #ed2025;
}
/* modal */
.modal-portfolio .modal-close:hover::before,
.modal-portfolio .modal-close:focus::before,
.modal-portfolio .modal-close:hover::after,
.modal-portfolio .modal-close:focus::after {
  content: '';
  background-color: #ed2025;
}
/*
	==================
	[11. CONTACT]
	==================
*/
.contact-form .form-control:focus {
  color: #ed2025;
}
/*
	==================
	[12. SOCIAL]
	==================
*/
#social {
  background-color: #ed2025;
}
/*
	==================
	[13. FOOTER]
	==================
*/
.footer .footer-widget .widget-header {
  position: relative;
}
.footer .footer-widget .widget-header::before {
  font-family: 'FontAwesome';
  content: '\f013';
  position: absolute;
  left: -30px;
  top: -4px;
  color: #ed2025;
}
.footer .footer-widget .widget-header::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 25px;
  height: 1px;
  background-color: #ed2025;
}
.footer .footer-widget ul > li > a:hover,
.footer .footer-widget ul > li > a:focus {
  color: #ed2025;
}
.footer .footer-widget-logo h2 {
  color: #ed2025;
}
